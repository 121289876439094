<template>
  <div class="hello">
    <MenuItem @changeUrl="changeUrl" :sceneData="sceneData" class="menuitem-box"></MenuItem>
    <VirSim :iframeUrl="iframeUrl" class="virsim-box"></VirSim>
  </div>
</template>

<script>
import MenuItem from './Menu-Item.vue'
import VirSim from './Vir-Sim.vue'
export default {
  name: 'middleware-nginx',
  data(){
    return {
      sceneData: [
        { name: 'Nginx服务框架与基本原理的仿真', 
          isSelect: true,
          url: '/middleware-nginx-base/'},
      ],
      iframeUrl: '/middleware-nginx-base/'
    }
  },
  components: {
    MenuItem, VirSim
  },
  methods: {
    changeUrl(url){
      this.iframeUrl = url
    }
  }
}
</script>

<style scoped>
  .virsim-box{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .menuitem-box{
    position: absolute;
    z-index: 1;
  }
</style>
