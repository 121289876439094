import { render, staticRenderFns } from "./docker-image32.vue?vue&type=template&id=342ba22f&scoped=true&"
import script from "./docker-image32.vue?vue&type=script&lang=js&"
export * from "./docker-image32.vue?vue&type=script&lang=js&"
import style0 from "./docker-image32.vue?vue&type=style&index=0&id=342ba22f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "342ba22f",
  null
  
)

export default component.exports