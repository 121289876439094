<template>
  <div class="hello">
    <MenuItem @changeUrl="changeUrl" :sceneData="sceneData" class="menuitem-box"></MenuItem>
    <VirSim :iframeUrl="iframeUrl" class="virsim-box"></VirSim>
  </div>
</template>

<script>
import MenuItem from './Menu-Item.vue'
import VirSim from './Vir-Sim.vue'
export default {
  name: 'linux-cmd',
  data(){
    return {
      sceneData: [
        { name: '包含命令基本格式解析仿真（命令选项参数，强调空格的切分）', 
          isSelect: true,
          url: '/linux-cmd-format/'},
        { name: '命令输入输出与管道的仿真', 
          isSelect: false,
          url: '/linux-cmd-sop/'},
      ],
      iframeUrl: '/linux-cmd-format/'
    }
  },
  mounted () {
    this.$router.push({name: 'linux-cmd'})
  },
  components: {
    MenuItem, VirSim
  },
  methods: {
    changeUrl(url){
      console.log(url)
      this.iframeUrl = url
    }
  }
}
</script>

<style scoped>
  .virsim-box{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .menuitem-box{
    position: absolute;
    z-index: 1;
  }
</style>
