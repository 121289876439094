<template>
  <div class="hello">
    <MenuItem @changeUrl="changeUrl" :sceneData="sceneData" class="menuitem-box"></MenuItem>
    <VirSim :iframeUrl="iframeUrl" class="virsim-box"></VirSim>
  </div>
</template>

<script>
import MenuItem from './Menu-Item.vue'
import VirSim from './Vir-Sim.vue'
export default {
  name: 'network-sw',
  data(){
    return {
      sceneData: [
        { name: '网络数据交换的基本原理仿真', 
          isSelect: true,
          url: '/network-sw/'},
        { name: 'VXLAN原理仿真', 
          isSelect: false,
          url: '/network-vxlan/'},
      ],
      iframeUrl: '/network-sw/'
    }
  },
  components: {
    MenuItem, VirSim
  },
  methods: {
    changeUrl(url){
      this.iframeUrl = url
    }
  }
}
</script>

<style scoped>
  .virsim-box{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .menuitem-box{
    position: absolute;
    z-index: 1;
  }
</style>
