<template>
  <div class="vs-box">
    <iframe class="router_view" :src="localtionUrl + iframeUrl"></iframe>
    <!-- <iframe class="router_view" :src="'http://183.67.38.142:58440/' + iframeUrl"></iframe> -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      localtionUrl: window.location.origin
    }
  },
  props: ['iframeUrl']
}
</script>

<style>
.vs-box{
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.router_view{
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
}
</style>