<template>
  <div class="menu-box">
    <div class="all-box" v-for="(item, index) in sceneData" :key="index">
      <img src="../assets/icon/select.png" class="single-img" v-show="item.isSelect">
      <img src="../assets/icon/default.png" class="single-img" v-show="!item.isSelect">
      <div class="single-box" :title="item.name" @click="clickEvent(item.url, index)">
        <p class="single-box-p" >{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // isCollapse: true
    }
  },
  props: ['sceneData'],
  methods: {
    clickEvent(url, index) {
      for(let i = 0;i < this.sceneData.length;i++){
        if(i == index){
          this.$set(this.sceneData[i], 'isSelect', true)
        } else {
          this.$set(this.sceneData[i], 'isSelect', false)
        }
      }
      this.$forceUpdate()
      this.$emit('changeUrl', url)
    },
  }
}
</script>

<style>
.all-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.single-img{
  width: 280px;
  height: 50px;
  margin-bottom: 16px;
}
.menu-box{
  margin-top: 60px;
}
.single-box{
  position: absolute;
  color: #FFFFFF;
  font-size: 14px;
  min-height: 50px;
  min-width: 120px;
  margin-bottom: 16px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  width: 280px;
  text-align: left;
  padding-left: 60px;
}
.single-box:hover{
  cursor: pointer;
}
.single-box-p{
  white-space: pre-line;
  word-wrap: break-word;
  word-break: break-all;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>